import {
  getHmTemplatesApi,
  createTemplateApi,
  updateTemplateApi,
  deleteTemplateApi,
} from '@/api/templatesApi';

import {
  CREATE_HM_TEMPLATE_REQUEST,
  DELETE_HM_TEMPLATE_REQUEST,
  FETCH_HM_TEMPLATES_REQUEST,
  FETCH_HM_TEMPLATES_SUCCESS,
  UPDATE_HM_TEMPLATE_REQUEST,
  LOADING_STATE_SET,
  CLEAR_TEMPLATES_DATA,
} from './storeActions';

const getInitialState = () => {
  return {
    hmTemplates: [],
    hmTemplatesLoaded: false,
  };
};

const state = getInitialState();

const getters = {
  hmTemplates: (state) => state.hmTemplates,
  hmTemplatesLoaded: (state) => state.hmTemplatesLoaded,
};

const actions = {
  [FETCH_HM_TEMPLATES_REQUEST]: async ({commit}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading hiring manager templates'});
    try {
      const hmTemplates = await getHmTemplatesApi();
      if (!! hmTemplates) {
        commit(FETCH_HM_TEMPLATES_SUCCESS, {hmTemplates});
      }
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [CREATE_HM_TEMPLATE_REQUEST]: async ({commit, dispatch}, {payload}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Creating hiring manager template'});
    try {
      const template = await createTemplateApi({...payload, templateType: 'hiringManagerTemplate'});
      if (!! template) {
        dispatch(FETCH_HM_TEMPLATES_REQUEST);
      }
      return template;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_HM_TEMPLATE_REQUEST]: async ({commit, dispatch}, {payload, id}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Updating hiring manager template'});
    try {
      const template = await updateTemplateApi({payload, id});
      if (!! template) {
        dispatch(FETCH_HM_TEMPLATES_REQUEST);
      }
      return template;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [DELETE_HM_TEMPLATE_REQUEST]: async ({commit, dispatch}, {id}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Deleting hiring manager template'});
    try {
      const res = await deleteTemplateApi(id);
      if (!! res) {
        dispatch(FETCH_HM_TEMPLATES_REQUEST);
      }
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
};

const mutations = {
  [FETCH_HM_TEMPLATES_SUCCESS]: (state, {hmTemplates}) => {
    state = Object.assign(state, {hmTemplates, hmTemplatesLoaded: true});
  },
  [CLEAR_TEMPLATES_DATA]: (state) => {
    state = Object.assign(state, getInitialState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

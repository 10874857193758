import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import seats from './seats';
import payments from './payments';
import templates from './templates';
import teamTemplates from './teamTemplates';
import hiringManagerTemplates from './hiringManagerTemplates';
import atsIntegrations from './atsIntegrations';
import plans from './plans';
import texts from './texts';
import interviewReminder from './interviewReminder';
import jobStage from './jobStage';
import outofoffice from './outofoffice';
import onboarding from './onboarding';
import calendarshortcut from './calendarshortcut';
import {
  LOADING_STATE_SET,
} from './storeActions';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isLoading: false,
    loaderText: 'Loading',
  },
  getters: {
    getLoaderState: (state) => state.isLoading,
    getLoaderText: (state) => (!!state.loaderText) ? state.loaderText : 'Loading',
  },
  modules: {
    auth,
    seats,
    payments,
    templates,
    atsIntegrations,
    plans,
    texts,
    teamTemplates,
    hiringManagerTemplates,
    interviewReminder,
    jobStage,
    outofoffice,
    onboarding,
    calendarshortcut,
  },
  mutations: {
    [LOADING_STATE_SET]: (state, {isLoading, loaderText}) => {
      Object.assign(state, {isLoading, loaderText});
    },
  },
});

export default store;
